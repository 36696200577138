import { Line, mixins } from 'vue-chartjs';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    zoomUpdated: {
      default: 0,
      required: false,
    },
    chartData: {
      required: false,
      type: Object,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scaleBeginAtZero: false,
      }),
    },
  },
  mounted() {
    // Register the annotation plugin
    // @ts-ignore
    Chart.plugins.register(chartjsPluginAnnotation);
    this.addPlugin(chartjsPluginAnnotation);
    this.renderChart(this.chartData, this.options);
  },
  beforeDestroy() {
    if (this.$data && this.$data._chart) {
      this.$data._chart.destroy();
    }
  },
  methods: {
    // Method to programmatically show tooltip for specific dataset and index
    showTooltip(datasetIndex, index) {
      const chart = this.$data._chart;
      if (!chart) return;

      // Manually create active points to show tooltip in Chart.js v2
      const meta = chart.getDatasetMeta(datasetIndex);
      const activeElement = meta.data[index];

      // Simulate active element and tooltip activation
      chart.tooltip._active = [activeElement];
      chart.tooltip.update();
      chart.draw();
    },
    // Method to hide the tooltip programmatically
    hideTooltip() {
      const chart = this.$data._chart;
      if (!chart) return;

      // Clear the active tooltip
      chart.tooltip._active = [];
      chart.tooltip.update();
      chart.draw();
    },
  },
  watch: {
    yAxisRange(val) {
      this.renderChart(this.chartData, this.options);
    },
    zoomUpdated(val) {
      this.renderChart(this.chartData, this.options);
    },
  },
};
